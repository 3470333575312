
body,
html {
font-family: 'Lato', sans-serif;
    word-spacing: 1.1px;
    line-height: 1.5;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    
    /* Adjust as needed to improve readability */
    color: #000000;
    /* Dark text color for contrast */
}

/* Add any other global styles you want, for example: */
.container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 20px;
    font-size: 15px;
    font-weight: 400;
}

/* Add styles for spacing */
.spacing {
    height: 1vh;
}

.img{
    width: 100%;
    height: auto;

}
.img-container {
    max-width: 500px;
   max-height: 300px;
    overflow: hidden;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}